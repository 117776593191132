/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* sass utility függvények és változók */
/******** <VARIABLES> ************/

$color-ck-red: #b73526;
$color-ck-green: #45d52f;
$color-ck-green2: #2b921b;
$color-ck-blue: #21588c;
$color-ck-blue-hover: #116fbf;
$color-ck-blue2: #004896;
$color-link-blue: #007ad9;
$bg-grey1: #f5f5f5;
$bg-light-blue:#f6fbff;
$font-color1: #212529;
$error-color: #dc3545;
$border-color: #c8c8c8;
$color-link-reverse: #fff;
$border-radius: 5px;
$color-ck-yellow: #d2c38e;


/******** </VARIABLES> ************/

$imagesPath: '../images';

@mixin contentImage($image) {
  $path: $imagesPath + "/" + $image;
  content: url($path);
  background-image: none !important;
  box-sizing: border-box;
  position: relative;
  top: 2px;
  display: inline-block;
}

@mixin color-ck-blue-striped {
  background-color: #22578c;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23255f99' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23255f99' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin color-ck-yellow-striped {
  background-color: #d2c38e;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23ccbd8a' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23ccbd8a' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin color-ck-red-striped {
  background-color: $color-ck-red;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23c13828' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23c13828' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin color-ck-white-striped {
  background-color: #f1f1f1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23ffffff' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23ffffff' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin color-ck-green-striped {
  background-color: #4bb73a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23429e34' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23429e34' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg) ;
  transform: rotate($deg);

}

