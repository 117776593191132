@import 'utils'; /* sass utility függvények */

.p-fieldset {
  legend {
    display: inline-block;
    width:auto;
  }
}

body {
  .ck-main {
    .btn
    {
      &.p-button,&.btn-primary {
        @extend %blue-button;
      }
    }
    .p-button {
     @extend %blue-button;
      padding:0;
      &.p-disabled {
        color:white;
      }
    }
  }

  .blue-button {
    @extend %blue-button;
    color:white;
  }

  %blue-button {
    background-color: $color-ck-blue;
    border: 1px solid $color-ck-blue;
    &:hover {
      background-color: $color-ck-blue-hover;
      border: 1px solid $color-ck-blue-hover;

    }
  }
}



.p-dropdown {
  &.form-control {
    width:100% !important;
  }
  .p-dropdown-label.p-inputtext {
    padding:0.1em;
  }
}

.form-inline .p-dropdown {
  &.form-control {
    width:auto !important;
  }
}

.p-autocomplete {
  &.form-control {
    height:auto;
    input {
      width:100%  !important;
    }
  }
}

.p-panel.p-component {
  .p-panel-content {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-top: 1px solid $border-color;
  }
  .p-panel-titlebar + .p-toggleable-content > .p-panel-content {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
  }
}
.p-calendar {
  &.form-control {
    input {
      width:calc(100% - 2.357em);
      height:100%
    }
    .p-calendar-button {
      //height: 35px;
    }
  }
}
body .p-datatable .p-datatable-tbody > tr.p-datatable-row + tr > td[colspan] {
  //padding-bottom: 0;
  //padding-right: 0;
  //padding-top:0;
}

body .p-growl {
  .p-growl-item-container .p-growl-item div.p-growl-message {
    margin: 0 0 0 0em;
  }
  .p-growl-image {
    display:none;
  }
}

body .p-card.p-component {
  border: 1px solid $border-color;
  box-shadow: none;
  border-radius: $border-radius;
  .p-card-body {
    border-radius: $border-radius;
    background: #f8f9fa;
  }
}

body .p-selectbutton.form-control {
  width:auto;
  padding:0;
  height:auto;
  background:transparent;
  border:none;
  .p-button {
    border: 1px solid #ced4da;
    background-color: #dadada;
    &.p-highlight {
      background-color: $color-ck-blue;
      border: 1px solid $color-ck-blue;
    }
  }
}

body .p-button {
  padding:0 !important;
}

body .p-progressbar {
  border-radius: 5px;
}

body .p-paginator {
  border:none !important;
  margin-top:2px;
  .p-dropdown-label {
    border-radius: 0;
    padding:5px 0 !important;
    width:90%;
  }
}

body {
  .p-datatable,
  .p-treetable{
    table {
      border: none;

      .p-datatable-thead > tr > th,
      .p-treetable-thead > tr > th,
      .p-datatable-tbody > tr > td,
      .p-treetable-tbody > tr > td {
        border: none;
        border-left: 2px solid white;

        &:first-child {
          border-left: none;
        }
      }

      .p-datatable-thead,
      .p-treetable-thead{
        > tr > th {
          //border-bottom: 2px solid #e1e1e1;
          background-color: $color-ck-blue;
          color: white;
          font-weight: normal;
        }
        > tr > th {
          &:focus {
            border: none !important;
            border-left: 2px solid white !important;
            background-color: #2f689e !important;
          }
        }
      }

      .p-datatable-tbody,
      .p-treetable-tbody{
        > tr {
          &:nth-child(2n) {
            border-top: 2px solid #f3f0f0;
            border-bottom: 2px solid #e1e1e1;
            background-color: #f9f9f9;
            &:focus {
              border-top: 2px solid #f3f0f0 !important;
              border-bottom: 2px solid #e1e1e1 !important;
            }
          }
        }
      }

      .p-sortable-column:not(.p-highlight):hover {
        background-color: #2f689e;
        color: white;

        .p-sortable-column-icon {
          color: white;
        }
      }

      .p-sortable-column {
        .p-sortable-column-icon {
          color: white;
        }
      }
    }
  }
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #3068a0 !important;
}


