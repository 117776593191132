/* alap komponens. */
/* project specifikus design elemek */
//@import '~open-iconic/font/css/open-iconic-bootstrap.css';
@import "~bootstrap/scss/bootstrap";
@import 'header_menu'; /* fejléc és menü design elemek*/
@import 'icons'; /* képek és ikonok */
@import 'pfMain'; /* primefaces specifikus design elemek */
@import 'scrMain'; /* Scriptumos stílus osztályok */
@import 'utils'; /* sass utility függvények */


.page-center-content {
  width: 100%;

  @media only screen and (min-width : map-get($grid-breakpoints, xs)) {
    width: 96%;
    margin: 0 auto !important;
  }
  @media only screen and (min-width : map-get($grid-breakpoints, xl)) {
    width: 100%*10/12;
    margin: 0 auto !important;
  }
}

html {
  //background: #e5e5e5;
}

html, body, #root, .registration-layout {
  height:100%;

}

.registration-layout {
  @include color-ck-blue-striped();

  .left-column {
    background: url(../images/background.jpg);
    background: url(../images/background.jpg);
    background-position: top;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: cover;
    position: relative;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .p-card-body {
    padding:0;
    border-radius: $border-radius;
  }
  .registration-content {
    h1 {
      font-weight: normal;
      text-transform: uppercase;
      color: $color-ck-blue;
    }
  }
}

.balances-content {
  .api-key {
    color: $color-ck-blue;
    font-style: italic;
  }
}


body.ck-body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.kezelo-container {
  .result.card {
    background-color: $bg-light-blue;
    label {
      &:after {
        content: ':';
      }
    }
  }
}
.highlight-red {
  color: #ce0000 !important;
  font-weight: normal !important;
}

.highlight-green {
  color: $color-ck-green2 !important;;
  font-weight: normal !important;
}

.highlight-blue {
  color: $color-link-blue !important;
  font-weight: normal !important;
}

@mixin result-card-bg {
  background: rgba(103,140,184,0.3);
  //@include gradient-x-three-colors(rgba(103,140,184,0.7), rgba(63,106,163,0.7), 40%, rgba(76,119,176,0.7));
  @include gradient-x-three-colors(#b2c5db, #9eb4d0, 40%, #a5bad7);
}


.result.card {
  border:1px solid #e8e8e8;
  padding:0px;
  border-radius:5px;
  @include result-card-bg();

  .result-content {
      .data-col {
        padding: 20px 20px 20px 20px;
      }
    .image-col {
      padding:20px;
      background-color: $color-ck-blue;
      border-top:1px solid $color-ck-blue;
      border-bottom:1px solid $color-ck-blue;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

    }
    .img-column {
      width:168px;
      color:white;
    }
  }
  .result {
    font-weight:bold;
    label {
      font-weight:normal;
      &:after {
        content: ':';
      }
    }
    .highlight-red {
      color: #a51414 !important;
      font-weight: bold !important;

    }
    label.highlight-red {
      font-weight: normal !important;
    }
  }
}

.input-panel {
  .input-panel-content {
    border:1px solid #e8e8e8;
    //border-left: 5px solid $color-ck-red;
    border-radius:5px;
    //padding:20px;
    background: rgba(242,242,242,1);
    @include gradient-x-three-colors(rgba(242,242,242,1), rgba(252,252,252,1), 41%, rgba(235,232,235,1));

    .blue-hr {
      border:1px solid #ece9ec;
    }
    section {
      border-bottom: 5px solid white;
      &:last-child {
        border-bottom: 1px solid #dadada;
      }
    }
  }
  .amount-panel-wrapper {
    background: #dadada;
    border: 1px solid #dadada;
    .amount-panel {
      font-size:0.95rem;
      text-align: right;
      .amount-value {
        border-radius: 5px;
        display:inline-block;
        font-weight: bold;
        .quota {
          padding-right:5px;
        }
        .days {
          padding-left:5px;

        }
      }
    }
  }
}

.sep{
  padding-bottom:1.65rem;
  align-self:flex-end;
}

.break-all {
  word-break: break-all;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes show {
  0% {
    transform: scaleY(0);
    height:0;
  }
  100% {
    transform: scaleY(1);
    height:auto;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: scaleY(1);
    height:100%;
  }
  99% {
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
    height:0;
  }
}
h2 {
  font-sizte: 2.5rem !important;
}

hr.short-red {
  border-bottom: 3px solid $color-ck-red;
  width:100px;
}

.main-page-container .kezelo-form-content,
.batch-page-content{
  h1 {
    text-transform: uppercase;
    color: $color-ck-blue;
  }
}

.main-page-container {
  .inverse-bg {
    background: white;
  }
  .kezelo-forms {
    .kezelo-form-content {

      .img-box {
        background-color: #be372b;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23b53429' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23b53429' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
        width:160px;
        height:160px;
        border-radius:10px;
        padding-top:22px;
        position: relative;
        div {
          border-radius:5px;
          position:absolute;
          top:10px;
          left:10px;
          bottom:10px;
          right:10px;
          background:grey;
        }
      }
      .right-column {
          border-radius:5px;
      }
    }
  }
  .form-demo {
    .result-button > button {
      margin-top: 2px !important;
    }
  }

  .kezelo-buttons {
    @include color-ck-blue-striped();
    a {
      color: #676767 !important;
      text-decoration: none !important;
      display: inline-block;
    }
    .kezelo-button {
      background: #e0e0e0;
      padding: 44px 6px 10px 6px;
      border-radius:10px;
      width:190px;
      height:190px;
      margin-top:20px;
      margin-bottom:20px;
      color: $font-color1;
      @include rotate(-2deg);
      -webkit-transition: transform 1s;
      transition: transform 1s;

      //FF hack
      @-moz-document url-prefix() {
        outline: 1px solid transparent; //FF "edge anti-aliasing" (forgatáskor az élek pixelesednek enélkül) IE11 alatt viszont pont, hogy ezzel lesz rossz.
      }

      img {
        //opacity: 0.5;
      }
      &.selected {
        color: $color-ck-red;
        font-weight: bold;
        padding-top: 41px;
        border:3px solid $color-ck-red;
        img {
          opacity: 1;
        }
      }

      &:hover {
        color: $color-ck-red;
        background: white;
        padding-top: 41px;
        border:3px solid $color-ck-yellow;
        color: $color-ck-red;
        font-weight: bold;
        @include rotate(0deg);
        img {
          opacity: 1;
        }
      }
    }
  }
  .intro-content {
    h1 {
      font-size: 3rem;
      font-weight:bold;
    }
    h3:first-child {
      font-weight:bold;
    }
  }
  .more-info-content {
    background: white;
    //border-top:3px solid $color-ck-red;
    .info-box {
      max-width:350px;
      article {
        background-color: #d2c38e;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23ccbd8a' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23ccbd8a' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
        border-top: 3px solid #9e9266;
        border-bottom: 3px solid #9e9266;
        //border-bottom: 3px solid #c1c1c1;
        border-radius:5px;
        .p-button {
          background: $color-ck-blue;
          border-color: $color-ck-blue;
        }
      }
    }
  }

  .attribute-content {
    background: rgba(200,200,200,0.1);
    border-bottom: 3px solid $color-ck-red;
  }

  .more-info-content2 {
    @include color-ck-yellow-striped();
  }
  .more-info-content3 {
    background: $color-ck-yellow;
    background: rgba(237,237,237,1);
    @include gradient-y(rgba(237,237,237,1), rgba(226,226,226,1));
    ul {
      list-style-type: none;
      li {
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        background-image: url('../images/li-tick-48.png');
        background-position: center left;
        list-style:none;
        background-repeat:no-repeat;
        padding-left:60px;
        min-height:50px;
        margin-bottom:20px;
        div {
          flex: 1;
        }
      }
    }
  }
  .reference-content {
    background:white;
    border-top: 3px solid $color-ck-blue;
    .image-box {
      text-align: center;
      margin: 10px 30px 30px;
      img {
        height:70%;
      }
    }
  }
  .file-upload .form-control {
    background-color: transparent;
  }
}

.white-striped {
  height:40px;
  @include color-ck-white-striped;
}

.yellow-button {
  background: $color-ck-yellow !important;
  border-color: $color-ck-yellow !important;
  .p-button-text {
    color: $color-ck-blue;
    font-weight: bold;
  }
}
.invert-button {
  background: transparent !important;
  border-color: $color-ck-blue !important;
  .p-button-text {
    color: $color-ck-blue;
    font-weight: bold;
  }
}

@mixin triangle(){
  position: absolute;
  display: inline-block;
  width:30px;
  height:30px;
  border-bottom: 15px solid $color-ck-red;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.batch-page-content {
  .file-upload {
    .form-control {
      background:transparent;
    }
  }

  .attribute-content {
    @include color-ck-yellow-striped();
  }

  .kezelo-buttons {
    @include color-ck-blue-striped();
    border-bottom: 3px solid $color-ck-red;
    padding-bottom:0 !important;

    a {
      color: #676767 !important;
      text-decoration: none !important;
    }
    .kezelo-button {
      position: relative;
      //padding: 34px 6px 10px 6px;
      text-align: center;
      border-radius:10px;
      width:150px;
      height:120px;
      margin:20px auto;
      text-transform: uppercase;
      color:white;
      top:0;
      border: none;
      background: transparent;
        transition: transform 1s;
        -webkit-transition: transform 1s;

      .triangle-bl {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
        -webkit-transition: opacity 0.3s, visibility 0.3s;
      }

      &:hover,
      &.selected {
        font-weight: bold;

        img {
          opacity: 1;
        }

        .triangle-bl {
          @include triangle();
          left:60px;
          transform: rotate(0deg);
          visibility: visible;
          opacity: 1;
        }
      }

      &.selected {
        color: $color-ck-yellow;
          font-size:110%;
          transform: scale(1.01);
          -webkit-transform: scale(1.05);
        .triangle-bl {
          bottom:-21px;
        }
      }

      &:hover {
          transform: scale(1.01);
          -webkit-transform: scale(1.05);
        .triangle-bl {
          bottom:-21px;
        }
      }
    }
  }
  .open-table-button {
    background: $color-ck-blue;
    padding:5px;
    border-radius: 5px;
    display:inline-block;
    &.disabled {
      opacity: .5;
    }
  }
  .remained-amount-panel {
    @include color-ck-white-striped();
    padding:10px;
    color: $color-ck-blue;
    font-weight:bold;
    .amount-panel {
      text-align:right;
    }
  }
  .hr-with-text {
    text-align:center;
    .col-auto:first-child {
      width:10px;
    }
    span {
      display: inline-block;
    }
    hr {
      margin:0;
      border-top: 1px solid  $color-ck-red;
    }
    .title {
      font-weight: bold;
      color:  $color-ck-blue;
    }
  }
}

.remained-amount-panel {
  @include color-ck-white-striped();
  padding: 10px;
  color: $color-ck-blue;
  font-weight:bold;

  .amount-panel {
    text-align: left;
  }
}


@keyframes mymove {
  from {top: 0px;}
  to {top: 200px;}
}

@media only screen and (max-width : 540px) {
  .batch-page-content
  {
    .kezelo-buttons .kezelo-button{
      &:hover, &.selected {
        .triangle-bl {
          bottom:60px !important;
          left:-20px !important;
          transform: rotate(90deg) !important;

        }
      }
    }
    .remained-amount-panel .amount-panel {
      text-align: left !important;
    }
  }
}

.collapsable-info-panel {
  table {
    width:100%;
    > caption {
      caption-side: top;
      font-size: 1.25rem;
      color: $font-color1;
    }
    tr:nth-child(2n) {
      background-color: #e1ebf9;
      > td {
        border-left:1px solid #d2dcea;
        &:first-child {
          border-left:none;
        }
      }
    }
    thead > tr {
      background-color: #bec8d6;
    }
    th, td {
      padding: 5px 10px;
    }
    &.description-table-1 {
      td {
        text-align:right;
      }
    }
    &.description-table-2 {
      @media only screen and (min-width : map-get($grid-breakpoints, lg) ) and (max-width : map-get($grid-breakpoints, xl) ) {
        th {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            div {
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 45px;
              overflow: hidden;
            }
          }
        }
      }
      td {
        text-align:center;
        &:first-child {
          text-align:left;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left:0;
    li {
      display: inline-block;
      align-items: center;
      background-image: url('../images/li-tick-48.png');
      background-position: center left;
      list-style:none;
      background-repeat:no-repeat;
      padding-left:60px;
      min-height:50px;
      margin-bottom:20px;
      &:last-child {
        margin:0;

      }
      div {
        flex: 1;
      }
    }
  }
}
.xray-form {
  .h-100 {
    .result.card {
      border-bottom:none;
    }
  }
  .result.card.desc {
    border-top:none;
  }
}

.collapse-panel {
  background:white;
}

.scroll-up-button-container
{
    background-color: rgba(50, 50, 50, 0.25);
  height: 50px;
  position: fixed;
  bottom: 20px;
  width: 50px;
  transition: opacity 0.5s ease-in-out 0s, right;
  cursor: pointer;
  opacity: 0;
  right: -50px;
  z-index: 1000;
}

.scroll-up-button-transition {
  background-color: rgba(50, 50, 50, 0.25);
  height: 50px;
  position: fixed;
  bottom: 20px;
  width: 50px;
  transition: opacity 0.5s ease-in-out 0s, right;
  cursor: pointer;
  opacity: 1;
  right: 20px;
  z-index: 1000;
}

.impress-content {
  .col-form-label {
    color: #77631d;
    &:after {
      content: ':';
    }

  }
}










