@import 'utils'; /* sass utility függvények */

//gombokhoz ikon
.icon-label-user-24:before
{
  @include contentImage('user-login-24.png');
}

.icon-label-copy-16:before
{
  @include contentImage('copy-16.png');
}

.icon-label-close-24:before
{
  @include contentImage('close2-24.png');
}

.icon-label-more-48:before
{
  @include contentImage('more-sqr-48.png');
  top:0;
  width:48px;
  height:48px;
  border-radius: 24px;
  overflow: hidden;
}
.icon-label-less-48:before
{
  @include contentImage('more-sqr-48.png');
  transform: rotate(180deg);
  top:0;
  width:48px;
  height:48px;
  border-radius: 24px;
  overflow: hidden;
}


