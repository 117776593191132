@import 'utils'; /* sass utility függvények */
@import "~bootstrap/scss/bootstrap";

.default-layout {
  .ck-header {
    min-height:auto;
    .page-center-content {
      position: initial;
      top:auto;
      right:auto;
      bottom:auto;
      left:auto;
    }
  }
}
.ck-header-main {


}

a.logo {
  .logo-img {
    height:48px
  }
  .logo-text {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: $color-ck-blue;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  &,&:hover {
    color: $font-color1;
    text-decoration: none;
  }
}

.distrib-type {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  text-transform: lowercase;
  letter-spacing: 1px;
  &.dev {
    color: $color-ck-red;
  }

  &.test {
    color: $color-ck-green2;
  }
}


.ck-header {
  background: -webkit-gradient(linear,left top,left bottom,from(rgba(22,22,22,.1)),color-stop(75%,rgba(22,22,22,.1)),to(#e6e6e6)),url(../images/background.jpg);
  background: linear-gradient(to bottom,rgba(22,22,22,.1) 50%,rgba(22,22,22,.1) 70%,#e6e6e6 100%),url(../images/background.jpg);
  background-position: top;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
  //border-bottom: 3px solid $color-ck-red;
  //@include  gradient-directional(#555, #000, 0%, 100%, 225deg);
  h1 {
    font-weight: bold;
    //text-shadow: 1px 1px 10px #b3b3b3;
    color: #333333
  }
  h3 {
    color: #333333
  }
  .page-center-content {
    //position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  a {
    &,&:hover {
      color: $font-color1;
      text-decoration: none;
    }
  }
  .navbar {
    .nav-item {
      margin-left: 10px;
      margin-right: 10px;
      a {
        color: rgba(0,0,0,0.7);
        font-weight: bold;
        position: relative;
        &:hover {
          color: #000;
        }

        &:before {
          //content: "";
          //position: absolute;
          //width: 100%;
          //  opacity: .2;
          //height: 1px;
          //bottom: -3px;
          //left: 0;
          //background-color: $color-ck-blue;
          //visibility: hidden;
          //width:0;
          //-webkit-transition: all 0.3s ease-in-out 0s;
          //transition: all 0.3s  0s;

        }
        &:hover:before {
          //visibility: visible;
          //width:100%;
          //box-shadow: 0px 0px 1px 1px $color-ck-red;
        }
      }
    }
    //@media only screen and (max-width : map-get($grid-breakpoints, sm)) {
    //  .navbar-collapse {
    //    position: absolute;
    //    z-index: 1;
    //    right: 0px;
    //    top:43px;
    //    border-radius: 5px;
    //    .navbar-nav {
    //      background: #4c9e2e;
    //      text-align: right;
    //      padding: 10px;
    //      border-radius: 5px;
    //    }
    //  }
    //}
  }
  .user-login-panel {
    position:relative;
    overflow: visible;
    .p-overlaypanel {
      top:28px !important;
      left: initial !important;
      right:-3px !important;
      font-size: 0.8rem;
      .name {
        font-size: 1rem;
      }
      .operator {
        text-transform: lowercase;
      }
    }
    a {
      cursor: pointer;
    }
    .logged-in-text {
      line-height: 1.3rem;
    }
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, sm)) {
    .login-panel.p-overlaypanel {
      &:after {
        left: auto;
        right: 1.25em;
      }
    }
}


body .login-panel {
  .form-control {
    display:block !important;
    width:100%;
  }
}
body .login-panel.visible {
  max-width: 270px;
  width: 270px;
  left:8px !important;
  top:44px !important;
  @media only screen and (min-width : map-get($grid-breakpoints, sm)) {
    left:initial !important;
    right:8px;
    top:35px !important;
  }
}

.footer {
  //border-top: 1px solid $bg-grey1;
  background:white;
  position: relative;
  a{
    color: #212529;

    & {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      font-weight: 600;
      color: #212529;
    }
  }
  ul.menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li
    {
      margin-bottom: 5px;
    }
  }
}

.default-layout,
.batch-layout{
  .footer {
    border-top:2px solid $color-ck-blue;
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(22,22,22,.1)),color-stop(75%,rgba(22,22,22,.1)),to(#e6e6e6)),url(../images/background.jpg);
    background: linear-gradient(to bottom,rgba(22,22,22,.1) 50%,rgba(22,22,22,.1) 70%,#e6e6e6 100%),url(../images/background.jpg);
  }
}

.default-layout{
  .footer {
    margin-top: 3rem;
  }
}
