@import 'utils'; /* sass utility függvények */

.error-message {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: $error-color;
}
.is-invalid
{
  &.p-checkbox
  {
    .p-checkbox-box {
      border: 1px solid $error-color;
    }
    + label {
      color: $error-color;
    }
  }
  &.p-radiobutton
  {
    .p-radiobutton-box {
      border: 1px solid $error-color;
    }
    + label {
      color: $error-color;
    }
  }
}

.select-button-editor {
  .tooltip-component {
    &, & > div {
      display: inline;
    }
  }
}
.tooltip-panel {
  text-align: left;
}

body {
  .p-radiobutton, .p-checkbox {
      &.selected+label {
        font-weight: bold;
      }
  }
}

.dropzone-content {
  border: 1px dotted $border-color;
  border-radius: 3px;
  padding:10px;
  text-align: center;
  color: $border-color;

}

body .p-datatable .p-datatable-tbody {
  > tr.interface-row > td:nth-child(2){
    color: $color-ck-red;
  }
  > tr.service-row > td:nth-child(2){
    color: $color-ck-blue;
  }
}

body .p-treetable .p-treetable-tbody {
  > .root-row > td:first-child span + div {
    display:inline;
  }
  > tr.interface-row > td:nth-child(1){
    color: $color-ck-red;
  }
  > tr.service-row > td:nth-child(1){
    color: $color-ck-blue;

  }
  > tr.interface-row, > tr.service-row {
    td:nth-child(1){
      > span:first-child {
        display:none;
      }
      > div {
        margin-left: 50px;
      }
    }
  }
  > tr.root-row.interface-row, > tr.root-row.service-row {
    td:nth-child(1){
      > span:first-child {
        display:inline-block;
      }
      > div {
        margin-left: 0px;
      }
    }
  }
}

.form-inline {
  .form-group {
    margin:0;
  }
}

.progress-bar {
  position: relative;
  height: 3rem;
  width: 100%;
  border-radius: 3px;
  border: 1px solid white;
  background-color: white !important;
  display:flex;
  display: -ms-flexbox;
  flex-direction: row;
  -ms-flex-direction: row;
  justify-content: flex-start;
  -ms-flex-pack: start;
  .filler {
    //background-color: #d2c38e;
    @include color-ck-green-striped;
    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23ccbd8a' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23ccbd8a' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
    height: 100%;
    border-radius: inherit;
    width:0;
    //transition: width 1s ease-in;
    animation: filler-anim 1s ease forwards;
    animation: filler-anim 1s ease forwards;
    -webkit-animation: filler-anim 1s ease forwards;
  }
  .filler-rest {
    @include color-ck-red-striped;
    border-left:1px solid #fff;
    border-radius: inherit;
    //transition: width 1s ease-in;
    animation: rest-anim 1s ease backwards;
    -webkit-animation: rest-anim 1s ease backwards;
  }
}

body {
  main a {
    color: $color-link-blue !important;
  }
  main footer a {
    color: $font-color1 !important;
  }
  .p-highlight > a {
    color: $color-link-reverse !important;
  }
}

:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: $color-ck-blue !important;
}

.break-word {
  word-wrap: break-word;
}

.scr-table {
  table {
    table-layout: auto;
    .icon-column {
      width:1%;
      white-space: nowrap;
    }
  }
}

.p-panel {
  .p-panel-content {
    border: none !important;
    //background-image: linear-gradient(to bottom, #fdfdfd, #fefefe, #fefefe, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
  }
  .p-panel-titlebar {
    background: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    text-transform: uppercase;
    .p-panel-title {
      font-size: 1.2rem;
      font-weight: normal !important;
    }
  }
}

h3.panel-title {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: normal;
  color: #333333 !important;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.p-15 {
  padding: 15px
}

.scr-dialog {
  @media only screen and (min-width : map-get($grid-breakpoints, xs)) {
    &.p-dialog {
      width: 90%;
    }
  }
  @media only screen and (min-width : map-get($grid-breakpoints, sm)) {
    &.p-dialog {
      width: 80%;
    }
  }
  @media only screen and (min-width : map-get($grid-breakpoints, md)) {
    &.p-dialog {
      width: 70%;
    }
  }
  @media only screen and (min-width : map-get($grid-breakpoints, lg)) {
    &.p-dialog {
      width: 60%;
    }
  }
  @media only screen and (min-width : map-get($grid-breakpoints, xl)) {
    &.p-dialog {
      width: 50%;
    }
  }
}
